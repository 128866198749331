<template>
  <div class="surprise-modal">
    <div v-if="!isVisibleSurpriseCrate">
      <div style="margin-top: 60px;" v-html="surpriseAwardPopupContent" />
      <div class="rosette-crate-container">
        <img
          v-for="(i, index) in 4"
          :key="'crate_' + index"
          :id="'crate_' + index"
          @click="selectCrate()"
          @mouseover="zoomInCrate(index)"
          @mouseleave="zoomOutCrate(index)"
          src="~@/assets/rosettes/rosette-modal-crate.svg"
          class="rosette-crate-image"
        />
      </div>
    </div>
    <div class="surprise-modal-video" v-else>
      <div class="video-container">
        <video
          @loadedmetadata="logCurrentSrc"
          ref="videoElementRef"
          width="630px"
          height="355"
          autoplay
        >
          <source src="~@/assets/rosettes/rosette-surprise-crate.mp4" />
        </video>
      </div>
      <div v-if="showScore">
        <div class="congratulation-message">
          TEBRİKLER
        </div>
        <div class="score-text">
          <strong>{{ getRosetteRewardPoint }}</strong> <br />Puan
        </div>
        <div class="modal-info">
          <img
            style="margin-right: 5px; width: 16px; height: 16px;"
            src="~@/assets/rosettes/rosette-modal-info.svg"
          />
          <div v-html="getRosetteRewardText" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RosetteInfo from '@/mixins/rosetteInfo.js';
import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'RosettesDetail',
  mixins: [RosetteInfo],
  data() {
    return {
      showScore: false,
      surpriseAwardPopupContent: '',
      isVisibleSurpriseCrate: false,
    };
  },
  computed: {
    getRosetteRewardPoint() {
      return this.rosetteRewardPoint;
    },
    getRosetteRewardText() {
      return this.rosetteRewardText;
    },
  },
  methods: {
    zoomInCrate(index) {
      const crate = document.getElementById('crate_' + index);
      crate.style.transform = 'scale(1.3)';
    },
    zoomOutCrate(index) {
      const crate = document.getElementById('crate_' + index);
      crate.style.transform = 'scale(1)';
    },
    selectCrate() {
      this.isVisibleSurpriseCrate = !this.isVisibleSurpriseCrate;
      this.setRosetteRewardPoint();
    },
    logCurrentSrc() {
      const video = this.$refs.videoElementRef;
      video.addEventListener('timeupdate', () => {
        const thresholdTime = 4.5;
        this.showScore = video.currentTime >= thresholdTime;
      });
    },
    getRosetteSurpriseAwardPopup() {
      StaticContent.getStaticContent('RosetteSurpriseAwardPopup').then(res => {
        const { content } = res.data.Data;
        this.surpriseAwardPopupContent = content;
      });
    },
  },
  async mounted() {
    this.getRosetteSurpriseAwardPopup();
    this.getRosetteRewardStatu();
  },
};
</script>

<style scoped lang="scss">
.surprise-modal {
  background-image: url('~@/assets/rosettes/rosette-surprise-modal.svg');
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 625px;
  height: 355px;
}

.rosette-crate-container {
  margin: 50px 30px 0 30px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.rosette-crate-image {
  width: 120px;
  height: 120px;
  margin: 0 10px;
  cursor: pointer;
}

.rosette-crate-image:hover {
  cursor: url('~@/assets/rosettes/rosette-surprise-key.svg'), auto;
}

.score-text {
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  color: #aa5a10;
  font-size: 20px;
  top: 115px;
  left: 315px;
}

.congratulation-message {
  background: linear-gradient(180deg, #ffd487 0%, #ffa300 60.53%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  transform: translateX(-50%);
  background-clip: text;
  position: absolute;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  top: 30px;
  left: 50%;
}

.modal-info {
  color: rgba(35, 48, 61, 0.8);
  transform: translateX(-50%);
  position: absolute;
  text-align: center;
  display: flex;
  font-weight: 400;
  font-size: 12px;
  bottom: 30px;
  left: 50%;
}
</style>
